/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useRef } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";
import AppearOnScroll from "~components/AppearOnScroll";
import Button from "~components/Button";
import Carousel from "~components/Carousel";
import GivePaireCTA from "~components/GivePaireCTA";
import Parallaxer from "~components/Parallaxer";
import ProductGrid from "~components/ProductGrid";
import Video from "~components/Video";
import ProductOverview from "~components/ProductOverview";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const IndexPage = ({ data, location }) => {
  const { device } = useContext(DocumentContext);

  const productsRef = useRef();

  //

  const cms = data.sanityIndexPage;

  //

  return (
    <>
      <SEO customTitle={cms.title} path={location.pathname} />

      <Layout className="index-page w-full relative">
        <section className="index-page__banner w-full sixteen-by-nine xs:h-auto relative flex xs:flex-col items-center justify-center bg-comet text-off-white overflow-hidden">
          <div className="grid z-10 xs:order-1 xs:pt-12">
            <div className="grid-end-4 xs:grid-end-12 grid-start-5 xs:grid-start-1 relative flex items-center justify-center xs:pt-12 xs:pb-12">
              <Parallaxer
                atTop
                className="flex flex-col items-center justify-center"
                severe={device === `desktop`}
              >
                <h1 className="animation-appear-down-slow animation-delay-3 f1 text-center">
                  {cms.heading}
                </h1>

                <Link
                  to="/products"
                  className="w-2/3 relative block mx-auto mt-v2 xs:mt-6 xs:py-4"
                >
                  <Button
                    className="animation-appear-down-slow animation-delay-5 w-full relative block bordered"
                    color="white"
                  >
                    <span className="f-button uppercase">
                      {cms.shopButtonText}
                    </span>
                  </Button>
                </Link>
              </Parallaxer>
            </div>
          </div>

          <div className="animation-appear animation-delay-3 w-full h-full xs:h-auto absolute xs:relative top-0 xs:top-auto right-0 xs:right-auto bottom-0 xs:bottom-auto left-0 xs:left-auto flex items-center justify-center xs:mt-32">
            <div className="w-full h-full absolute xs:relative top-0 right-0 bottom-0 left-0 flex items-center justify-center xs:order-0">
              <div className="index-page__banner__video">
                <Video
                  src={cms.heroVideo.asset.url}
                  muted
                  autoPlay
                  playsInline
                  loop
                />
              </div>
            </div>

            <div className="circle-rotate-infinite w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
              <img
                className="index-page__banner__circle h-full relative block overflow-hidden"
                src="/uploads/banner-type-animation.svg"
                alt="'Feels good' in a circle of text"
              />
            </div>
          </div>
        </section>

        {device === `desktop` && (
          <section className="w-full relative bg-off-white pt-16 pb-12">
            <div className="grid featured-categories">
              <h2 className="grid-end-12 mb-8 f3 italic text-center">
                {cms.subheading}
              </h2>

              {cms?.featureCards?.[0] &&
                cms.featureCards.map((featureCard, featureCardIndex) => {
                  const key = `feature-card-${featureCardIndex}`;

                  return (
                    <div
                      key={key}
                      className="index-page__card grid-end-4 relative overflow-hidden rounded-edge text-off-white text-center"
                    >
                      <AppearOnScroll>
                        {({ visible }) => {
                          const showImage =
                            visible && featureCard?.image?.asset?.fluid;

                          return (
                            <>
                              {showImage && (
                                <header
                                  className="animation-appear-left-slow w-full absolute top-0 right-0 left-0 z-10"
                                  style={{
                                    animationDelay: `${featureCardIndex * 150 +
                                      300}ms`
                                  }}
                                >
                                  <h4 className="mt-8 caption italic uppercase">
                                    {featureCard.subHeading}
                                  </h4>
                                  <h3 className="mt-2 f2">
                                    {featureCard.heading}
                                  </h3>
                                </header>
                              )}

                              {showImage && (
                                <figure
                                  className="animation-appear-right-slow"
                                  style={{
                                    animationDelay: `${featureCardIndex * 150 +
                                      300}ms`
                                  }}
                                >
                                  <Img
                                    className="w-full h-full relative block object-cover"
                                    fluid={featureCard.image.asset.fluid}
                                    alt={featureCard.image.altText}
                                  />
                                </figure>
                              )}
                            </>
                          );
                        }}
                      </AppearOnScroll>
                    </div>
                  );
                })}
            </div>
          </section>
        )}

        {device && (device === `mobile` || device === `tablet`) && (
          <section className="w-full relative block py-10 px-4">
            <h2 className="grid-end-12 mb-8 f3 italic text-center">
              {cms.subheading}
            </h2>

            {cms?.featureCards?.[0] && (
              <Carousel
                items={cms.featureCards.map((featureCard, featureCardIndex) => {
                  const key = `xs-feature-cart-${featureCardIndex}`;

                  return (
                    <div
                      key={key}
                      className="w-full relative rounded-edge overflow-hidden"
                    >
                      <header className="animation-appear-left-slow w-full absolute top-0 right-0 left-0 z-10 flex flex-col items-center text-center">
                        <h4 className="mt-8 caption italic uppercase text-off-white">
                          {featureCard.subHeading}
                        </h4>
                        <h3 className="mt-2 f2 text-off-white">
                          {featureCard.heading}
                        </h3>
                      </header>
                      <figure>
                        <Img
                          className="w-full h-full relative block object-cover"
                          fluid={featureCard.image.asset.fluid}
                          alt="Socks on feet"
                        />
                      </figure>
                    </div>
                  );
                })}
                withBullets
              />
            )}
          </section>
        )}

        <ProductOverview
          heading={cms.rightAngleBlock.heading}
          subheading={cms.rightAngleBlock.subHeading}
          text={cms.rightAngleBlock.text}
          image={cms.rightAngleBlock.image.asset.fluid}
          imageAlt={cms.rightAngleBlock.image.altText}
          position={cms.rightAngleBlock.position ? `right` : `left`}
          backgroundColor={cms.rightAngleBlock.backgroundColour}
        />

        <ProductOverview
          heading={cms.supremeClean.heading}
          subheading={cms.supremeClean.subHeading}
          text={cms.supremeClean.text}
          image={cms.supremeClean.image.asset.fluid}
          imageAlt={cms.supremeClean.image.altText}
          position={cms.supremeClean.position ? `right` : `left`}
          backgroundColor={cms.supremeClean.backgroundColour}
        />

        <section ref={productsRef}>
          <ProductGrid max={3} />
        </section>

        <GivePaireCTA />

        {/* {device !== `desktop` ? (
          <FullImage
            imgSrc={cms.footerBrand.asset.fluid}
            imgAlt={cms.footerBrand.altText}
            imgStyle={{ objectPosition: `30% center` }}
          />
        ) : (
          <FullImage
            imgSrc={cms.footerBrand.asset.fluid}
            imgAlt={cms.footerBrand.altText}
            imgStyle={{ height: `150%`, objectPosition: `50% center` }}
          />
        )} */}
      </Layout>

      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    sanityIndexPage {
      title
      heading
      subheading
      shopButtonText
      heroVideo {
        asset {
          url
        }
      }
      featureCards {
        subHeading
        heading
        image {
          altText
          asset {
            fluid(maxWidth: 768) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      rightAngleBlock {
        subHeading
        heading
        text
        image {
          altText
          asset {
            fluid(maxWidth: 768) {
              ...GatsbySanityImageFluid
            }
          }
        }
        position
        backgroundColour
      }
      supremeClean {
        subHeading
        heading
        text
        image {
          altText
          asset {
            fluid(maxWidth: 768) {
              ...GatsbySanityImageFluid
            }
          }
        }
        position
        backgroundColour
      }
      footerBrand {
        altText
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    allSanityProduct {
      edges {
        node {
          title
          frontPage
          tagline
          image {
            asset {
              fluid(maxWidth: 1024) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }

    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            title
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }

    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
            }
          }
        }
      }
    }
  }
`;
